import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { loggedInGuard } from './core/guards/logged-in.guard';

export const routes: Routes = [
    {
        path: '',
        canActivate: [loggedInGuard],
        loadComponent: () => import('./layouts/auth/auth.layout').then(c => c.AuthLayout),
        children: [
            {
                path: '',
                loadComponent: () => import('./routes/login/login.component').then(c => c.LoginComponent)
            }
        ]
    },
    {
        path: 'invite/:id',
        loadComponent: () => import('./routes/invite/invite.component').then(c => c.InviteComponent)
    },
    {
        path: 'dashboard',
        canActivate: [authGuard],
        loadComponent: () => import('./layouts/dashboard/dashboard.layout').then(c => c.DashboardLayout),
        children: [
            {
                path: 'departments-management',
                loadComponent: () => import('./routes/departments/departments.component').then(c => c.DepartmentsComponent)
            },
            {
                path: 'departments-management/roles-management',
                loadComponent: () => import('./routes/departments/routes/roles/roles.component').then(c => c.RolesComponent)
            },
            {
                path: 'gates-management',
                loadComponent: () => import('./routes/gates-management/gates-management.component').then(c => c.GatesManagementComponent)
            },
            {
                path: 'devices-management',
                loadComponent: () => import('./routes/devices-management/devices-management.component').then(c => c.DevicesManagementComponent)
            },
            {
                path: 'helpers',
                loadComponent: () => import('./routes/helpers-directory/helpers-directory.component').then(c => c.HelpersDirectoryComponent)
            },
            {
                path: 'helpers/add-helper',
                loadComponent: () => import('./routes/helpers-directory/routes/manage-helper/manage-helpers.component').then(c => c.AddHelpersComponent)
            },
            {
                path: 'helpers/edit-helper/:id',
                loadComponent: () => import('./routes/helpers-directory/routes/manage-helper/manage-helpers.component').then(c => c.AddHelpersComponent)
            },
            {
                path: 'staff-directory',
                loadComponent: () => import('./routes/staff-directory/staff-directory.component').then(c => c.StaffDirectoryComponent)
            },
            {
                path: 'staff-directory/manage-staff/:id',
                loadComponent: () => import('./routes/staff-directory/routes/manage-staff/manage-staff.component').then(c => c.ManageStaffComponent)
            },
            {
                path: 'staff-directory/manage-staff',
                loadComponent: () => import('./routes/staff-directory/routes/manage-staff/manage-staff.component').then(c => c.ManageStaffComponent)
            },
            {
                path: 'quick-contacts',
                loadComponent: () => import('./routes/quick-contacts/quick-contacts.component').then(c => c.QuickContactsComponent)
            },
            {
                path: 'patrol-check-points',
                loadComponent: () => import('./routes/patrol-checkpoints/patrol-checkpoints.component').then(c => c.PatrolCheckpointsComponent)
            },
            {
                path: 'patrol-shifts',
                loadComponent: () => import('./routes/patrol-shifts/patrol-shifts.component').then(c => c.PatrolShiftsComponent)
            },
            {
                path: 'patrol-logs',
                loadComponent: () => import('./routes/patrol-logs/patrol-logs.component').then(c => c.PatrolLogsComponent)
            },
            {
                path: 'accounts-and-transfers',
                loadComponent: () => import('./routes/accounts-and-transfers/accounts-and-transfers.component').then(c => c.AccountsAndTransfersComponent)
            },
            {
                path: 'flats',
                loadComponent: () => import('./routes/flats/flats.component').then(c => c.FlatsComponent)
            },
            {
                path: 'flats/activate-flat/:flatId',
                loadComponent: () => import('./routes/flats/routes/activate-flat/activate-flat.component').then(c => c.ActivateFlatComponent)
            },
            {
                path: 'flats/change-ownership/:flatId',
                loadComponent: () => import('./routes/flats/routes/change-ownership/change-ownership.component').then(c => c.ChangeOwnershipComponent)
            }
        ],
    },
    { path: '**', redirectTo: '' }
];